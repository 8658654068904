import { classNames } from "util/common";
import { FieldError } from "./FieldError";

export const DateInput = ({
	name = "date",
	label,
	value,
	register,
	formOptions = {},
	className = "",
	errors = {},
	isUpdated = false,
	placeholder = "",
	min,
	max,
	disabled = false,
}) => {
	const tailwindCss =
		"py-1 border w-full h-full transition-all rounded-md shadow-sm font-medium focus:ring-2 focus:ring-offset-2 focus:ring-jane-500 text-gray-800 bg-white hover:bg-gray-50 border-gray-300";

	return (
		<div className={classNames("", className)}>
			{!!label && <label>{label}</label>}
			<input
				value={value}
				placeholder={placeholder}
				className={classNames(
					tailwindCss,
					!!isUpdated[name] && "border-jane-400 bg-jane-200",
					errors[name] && "!border-red-500 bg-red-50 hover:bg-white",
					disabled &&
						"bg-jane-100 text-gray-400 cursor-not-allowed hover:bg-jane-100",
				)}
				min={min}
				max={max}
				type="date"
				disabled={disabled}
				{...register(name, {
					...formOptions,
					// Iphone Safari doesn't support min max on input[type=date]
					// so we need to validate it manually
					validate: value => {
						const selectedDate = new Date(value);

						if (min) {
							const minDate = new Date(min);
							if (selectedDate < minDate) {
								return "אנא הזן תאריך עתידי";
							}
						}

						if (max) {
							const maxDate = new Date(max);
							if (selectedDate > maxDate) {
								return "אנא הזן תאריך בעבר";
							}
						}

						return true;
					},
				})}
			/>
			{!!errors[name] && <FieldError message={errors[name].message} />}
		</div>
	);
};
