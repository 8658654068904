import { FieldError } from "components/form/FieldError";
import React from "react";
import { classNames } from "util/common";

function CommonField({
	register,
	errors = {},
	disabled = false,
	registerName = "input",
	placeholder = "",
	required = false,
	label = "",
	hideLabel = false,
	type = "text",
	additionalValidation,
	className = "",
	isUpdated = false,
	containerClassName = "",
	hint,
}) {
	return (
		<div className={containerClassName}>
			{!hideLabel && (
				<label
					htmlFor={registerName}
					className="block items-center text-base font-medium text-gray-700"
				>
					{label}
					{hint && <span className="text-xs text-jane-400 mr-1">{hint}</span>}
				</label>
			)}
			<input
				type={type}
				id={registerName}
				className={classNames(
					"block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-base focus:ring-1 disabled:cursor-not-allowed transition-all",
					!!errors[registerName]
						? "border-red-300 focus:ring-red-500 focus:border-red-500 bg-red-50 bg-opacity-50"
						: "border-gray-300 focus:ring-jane-500 focus:border-jane-500",
					disabled && "bg-jane-100",
					!!isUpdated[registerName] && "border-jane-400 bg-jane-200",
					!hideLabel && "mt-1",
					className,
				)}
				placeholder={placeholder}
				{...register(registerName, {
					required: required ? "שדה חובה" : required,
					...additionalValidation, // Additional custom validation rules
				})}
				disabled={disabled}
				autoComplete="off"
			/>
			{!!errors[registerName] && (
				<FieldError message={errors[registerName].message} />
			)}
		</div>
	);
}

export default CommonField;
