import { StarIcon } from "@heroicons/react/outline";
import ReviewButton from "components/reviews/ReviewButton";
import { useRouter } from "next/router";
import React from "react";
import { productAlt } from "util/common";

function LeaveReviewCard({
	product,
	setIsLoginOpen,
	refetch,
	isPrimaryButton = true,
	disableLinks = false,
}) {
	const router = useRouter();
	return (
		<>
			<div className="bg-white p-4 rounded-md flex gap-2 relative flex-col">
				{product.is_first_review && (
					<div className="absolute -top-2 right-2 left-0 border border-jane-700 w-fit bg-jane-500 text-white text-sm rounded-md px-1 flex justify-center gap-1 items-center">
						<StarIcon className="w-4 h-4 " />
						היה ראשון להשאיר ביקורת!
					</div>
				)}
				<div className="flex justify-between w-full gap-2 items-center">
					<span className="flex gap-2">
						<img
							src={product.product_img_url}
							alt={productAlt(product)}
							onClick={() => {
								if (!disableLinks) {
									router.push(`/products/${product.product_id}`);
								}
							}}
							className={`h-20 w-20 ${!disableLinks && "cursor-pointer"}`}
						/>
						<div className="flex flex-col justify-center">
							<p
								className={`font-bold text-xl leading-none mb-1 ${
									!disableLinks && "cursor-pointer"
								}`}
								onClick={() => {
									if (!disableLinks) {
										router.push(`/products/${product.product_id}`);
									}
								}}
							>
								{product.product_heb_name}
							</p>
							<p className="text-base leading-none">
								{product.product_eng_name}
							</p>
						</div>
					</span>
					<ReviewButton
						product={{
							id: product.product_id,
							heb_name: product.product_heb_name,
							eng_name: product.product_eng_name,
							main_img_url: product?.product_img_url,
						}}
						setIsLoginOpen={setIsLoginOpen}
						isSingleProductPage={false}
						onAfterSubmit={refetch}
						isPrimaryButton={isPrimaryButton}
					/>
				</div>
			</div>
		</>
	);
}

export default LeaveReviewCard;
