import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { Navigation, Pagination, A11y, Manipulation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/manipulation";
import { WBO } from "util/types";
import { ChevronLeftIcon } from "@heroicons/react/solid";
import { Button } from "components/form/Button";
import useWindowDimensions from "components/hooks/useWindowDimensions";
import PrescriptionCard from "./PrescriptionCard";

function PrescriptionsSwiper({
	prescriptions = [],
	viewOnly = false,
	addPrescription = () => {},
	disabledField = false,
	deletePrescription = () => {},
	max = 0,
	register = () => {},
	sortPrescriptionsByDate = () => {},
	errors = undefined,
}) {
	// We need it in case there are multiple swipers on same page to avoid conflicts
	const paginationClass = useMemo(() => {
		const randomStr = Math.random().toString(36).substring(2, 9); // Generates a valid substring
		return `pagination-${randomStr}`; // Prefix with a letter
	}, []);

	const swiperRef = useRef(null);
	const { width } = useWindowDimensions();
	const [isBeginning, setIsBeginning] = useState(true);
	const [isEnd, setIsEnd] = useState(false);

	const handleAdd = useCallback(async () => {
		if (!swiperRef.current || !swiperRef.current.swiper) return;
		await addPrescription();
		swiperRef.current.swiper.slideTo(prescriptions.length);
	}, [addPrescription, swiperRef, prescriptions]);

	useEffect(() => {
		if (!swiperRef.current || !swiperRef.current.swiper) return;
		const swiper = swiperRef.current.swiper;
		const relevantPrescriptionIndex = prescriptions.findIndex(
			prescription => prescription.relevant === 0,
		);
		if (relevantPrescriptionIndex >= 0) {
			swiper.slideTo(relevantPrescriptionIndex);
		}
	}, []);

	useEffect(() => {
		if (!swiperRef.current || !swiperRef.current.swiper) return;
		const swiper = swiperRef.current.swiper;
		swiper.pagination.render();
		swiper.pagination.update();
		setIsBeginning(swiper.isBeginning);
		setIsEnd(swiper.isEnd);
	}, [prescriptions]);

	const handlePrev = useCallback(() => {
		if (!swiperRef.current) return;
		swiperRef.current.swiper.slidePrev();
	}, []);

	const handleNext = useCallback(() => {
		if (!swiperRef.current) return;
		swiperRef.current.swiper.slideNext();
	}, []);

	const handleSlideChange = useCallback(() => {
		if (!swiperRef.current) return;
		const swiper = swiperRef.current.swiper;
		setIsBeginning(swiper.isBeginning);
		setIsEnd(swiper.isEnd);
	}, []);

	const handleDateBlur = useCallback(
		randomId => {
			sortPrescriptionsByDate();
			if (swiperRef.current && swiperRef.current.swiper) {
				const prescriptionIndex = prescriptions.findIndex(
					prescription => prescription.randomId === randomId,
				);
				swiperRef.current.swiper.slideTo(prescriptionIndex);
				swiperRef.current.swiper.update();
				swiperRef.current.swiper.pagination.update();
			}
		},
		[sortPrescriptionsByDate],
	);

	useEffect(() => {
		if (!errors || !swiperRef.current || !swiperRef.current.swiper) return;
		const errorIndex = errors.findIndex(error => !!error);
		if (errorIndex !== -1) {
			swiperRef.current.swiper.slideTo(errorIndex);
		}
	}, [errors]);

	return (
		<>
			<Swiper
				ref={swiperRef}
				dir="rtl"
				modules={[Navigation, Pagination, A11y, Manipulation]}
				spaceBetween={16}
				slidesPerView={
					WBO["md"] < width
						? prescriptions.length > 2
							? 2.1
							: 2
						: prescriptions.length > 1
						? 1.1
						: 1
				}
				className="w-full !overflow-unset !pt-4"
				pagination={{
					el: `.${paginationClass}`,
					clickable: true,
					renderBullet: (index, className) => {
						const bg =
							prescriptions?.[index]?.relevant > 0
								? "#7EA2BC"
								: prescriptions?.[index]?.relevant === 0
								? "#8CB7A2"
								: prescriptions?.[index]?.relevant < 0
								? "#D1A6A3"
								: "#d1d5db";

						return `<span class="${className}" style="background-color: ${bg};"></span>`;
					},
				}}
				onSlideChange={handleSlideChange}
			>
				{prescriptions.map((prescription, index) => (
					<SwiperSlide key={prescription.randomId}>
						<PrescriptionCard
							viewOnly={viewOnly}
							max={max}
							register={register}
							deletePrescription={deletePrescription}
							onBlur={handleDateBlur}
							order={prescription.order}
							prescription={prescription}
							errors={errors}
						/>
					</SwiperSlide>
				))}
			</Swiper>
			<div className="w-full flex justify-between flex-wrap-reverse">
				{!viewOnly &&
					(!prescriptions.length ? (
						<div className="bg-jane-50 p-1 flex gap-2 items-center w-full rounded-md">
							<Button
								className="w-fit"
								onClick={() => handleAdd()}
								disabled={disabledField || prescriptions.length >= 12}
							>
								<span className="p-1 text-base md:p-0">הוספה +</span>
							</Button>
							<span>
								מספרי מרשם ותאריכים
								<span className="mr-1 text-jane-500">(לא חובה)</span>
							</span>
						</div>
					) : (
						<Button
							className="w-fit"
							onClick={() => handleAdd()}
							disabled={disabledField || prescriptions.length >= 12}
						>
							<span className="p-1 text-base md:p-0">הוספה +</span>
						</Button>
					))}

				<div className={`flex items-center w-fit ${viewOnly && "mx-auto"}`}>
					{((WBO["md"] < width && prescriptions.length > 2) ||
						(WBO["md"] > width && prescriptions.length > 1)) && (
						<ChevronLeftIcon
							className={`text-jane-500 w-10 h-10 rotate-180 ${
								isBeginning ? "opacity-50" : "cursor-pointer"
							}`}
							onClick={handlePrev}
						/>
					)}
					<div
						className={`${paginationClass} flex gap-1 sm:gap-2 !w-fit`}
					></div>
					{((WBO["md"] < width && prescriptions.length > 2) ||
						(WBO["md"] > width && prescriptions.length > 1)) && (
						<ChevronLeftIcon
							className={`text-jane-500 w-10 h-10 ${
								isEnd ? "opacity-50" : "cursor-pointer"
							}`}
							onClick={handleNext}
						/>
					)}
				</div>
			</div>
		</>
	);
}

export default PrescriptionsSwiper;
