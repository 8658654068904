import { useController } from "react-hook-form";
import Select from "react-select";

export function RichSelect({
	options,
	value: defaultValue,
	placeholder,
	control,
	disabled = false,
	className = "",
	required = false,
	registerName = "select",
	...props
}) {
	const {
		field: { onChange, value },
		fieldState: { error },
	} = useController({
		name: registerName,
		control,
		rules: { required: required && "שדה חובה" },
		defaultValue: defaultValue,
	});

	return (
		<div className={"rich-select" + " " + className}>
			<Select
				options={options}
				placeholder={placeholder ?? "בחר..."}
				value={value || null}
				onChange={onChange}
				styles={getStyles(!!disabled, error)}
				noOptionsMessage={() => "לא נמצאו פריטים."}
				{...props}
			/>
		</div>
	);
}

const getStyles = (disabled, error) => {
	return {
		control: (base, state) => {
			return {
				...base,
				transition: "all .2s ease",
				minHeight: 40,
				paddingLeft: 0,
				paddingRight: 0,
				minWidth: 80,
				cursor: disabled ? "not-allowed" : "pointer",
				border: error
					? "solid 1px red"
					: state.menuIsOpen
					? "solid 1px #64748b"
					: "solid 1px rgb(209, 213, 219)",
				boxShadow: state.menuIsOpen
					? "0 0 0 1px #64748b"
					: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
				backgroundColor: error ? "#fef8f8" : "#fff",
				"&:hover": {
					border: state.menuIsOpen
						? "solid 1px #64748b"
						: "solid 1px rgb(209, 213, 219)",
				},
			};
		},
		multiValue: provided => ({
			...provided,
			borderRadius: "8px",
			margin: "3px 5px",
			padding: "0px 2px",
		}),
		valueContainer: (provided, state) => ({
			...provided,
			overflow: "visible",
		}),
		input: provided => ({
			...provided,
			margin: "0px",
		}),
		indicatorsContainer: provided => ({
			...provided,
			height: "auto",
			paddingTop: 0,
			paddingBottom: 0,
			display: "flex",
		}),
		dropdownIndicator: provided => ({
			...provided,
			paddingTop: 0,
			paddingBottom: 0,
		}),
		menu: provided => ({
			...provided,
			minWidth: 80,
			zIndex: 999,
			overflowY: "auto",
		}),
		menuPortal: base => ({ ...base, zIndex: 999 }),
		option: (base, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...base,
				cursor: "pointer",
				height: "auto",
				minHeight: 0,
				padding: "10px 12px",
				fontWeight: 400,
				background: isDisabled
					? "#fff"
					: isFocused
					? "rgba(0,0,0,0.025)"
					: "none",
				color: isDisabled ? "#D1D5DB" : isFocused ? "#1F2937" : "#374151",
				"&:active": {
					background: isDisabled
						? "#fff"
						: isFocused
						? "rgba(0,0,0,0.025)"
						: "none",
					color: isDisabled ? "#D1D5DB" : isFocused ? "#1F2937" : "#374151",
				},
			};
		},
	};
};
