import { TrashIcon } from "@heroicons/react/outline";
import CommonField from "components/fields/CommonField";
import React from "react";
import { formatDate } from "util/common";

function PrescriptionCard({
	viewOnly = false,
	order = 1,
	max = 6,
	register = () => {},
	prescription = {},
	deletePrescription = () => {},
	onBlur = randomId => {},
	errors = {},
	disabled = false,
}) {
	let color =
		prescription.relevant > 0
			? "#7EA2BC"
			: prescription.relevant === 0
			? "#8CB7A2"
			: prescription.relevant < 0
			? "#D1A6A3"
			: "#d1d5db";
	return (
		<div
			className="border-2 flex flex-col bg-white gap-4 overflow-y-hidden rounded-lg pb-4"
			style={{
				borderColor: color,
			}}
		>
			<div
				className=" w-full flex text-white justify-between text-sm px-4"
				style={{ background: color }}
			>
				<span className="text-white pb-0.5">
					{prescription.relevant > 0
						? "עתידי"
						: prescription.relevant === 0
						? "נוכחי"
						: prescription.relevant < 0
						? "לא בתוקף"
						: ""}
				</span>
				<span>
					({order}/{max})
				</span>
			</div>
			<div className="flex flex-col gap-4 px-4">
				<div className="flex items-center relative w-full">
					{viewOnly ? (
						<span># {prescription?.number ?? "-"}</span>
					) : (
						<>
							<span className="absolute right-4 top-0 bottom-0 flex items-center">
								#
							</span>
							<CommonField
								hideLabel
								register={register}
								registerName={`prescriptions.${order - 1}.number`}
								placeholder="מספר המרשם"
								required
								errors={errors}
								disabled={disabled}
								containerClassName="w-full md:w-auto"
								className="pr-7"
							/>
						</>
					)}
				</div>
				<div className="flex gap-2 w-full">
					<label className="min-w-0 w-full">
						תוקף מ:
						{viewOnly ? (
							<span className="block">
								{formatDate(prescription?.from_date) ?? "-"}
							</span>
						) : (
							<input
								className="w-full py-1 border rounded-md shadow-sm text-12px font-medium focus:ring-2 focus:ring-offset-2 focus:ring-jane-500 text-gray-800 bg-white hover:bg-gray-50 border-gray-300"
								type="date"
								disabled={disabled}
								{...register(`prescriptions.${order - 1}.from_date`, {
									required: "שדה חובה",
									onBlur: () => onBlur(prescription.randomId),
								})}
							/>
						)}
					</label>
					<label className="min-w-0 w-full">
						תוקף עד:
						{viewOnly ? (
							<span className="block">
								{formatDate(prescription?.to_date) ?? "-"}
							</span>
						) : (
							<input
								className="w-full py-1 border rounded-md shadow-sm text-12px font-medium focus:ring-2 focus:ring-offset-2 focus:ring-jane-500 text-gray-800 bg-white hover:bg-gray-50 border-gray-300"
								type="date"
								disabled={disabled}
								{...register(`prescriptions.${order - 1}.to_date`, {
									required: "שדה חובה",
									onBlur: () => onBlur(prescription.randomId),
								})}
							/>
						)}
					</label>
				</div>
				{!viewOnly && (
					<button
						disabled={disabled}
						onClick={() => {
							deletePrescription(prescription.randomId);
						}}
						className="w-fit cursor-pointer transition-colors hover:bg-jane-50 p-1 mr-auto border-b rounded-t-md border-jane-200 flex gap-1 items-center disabled:cursor-not-allowed"
					>
						<TrashIcon className="h-5 w-5" />
						מחיקה
					</button>
				)}
			</div>
		</div>
	);
}

export default PrescriptionCard;
