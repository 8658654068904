import React from "react";

function BreakLine({ className = "" }) {
	return (
		<div
			className={"w-full bg-jane-200 h-[1px] rounded-full" + " " + className}
		/>
	);
}

export default BreakLine;
